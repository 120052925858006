import React from "react";
import { Helmet } from "react-helmet";
// import edgeBanner from "../../images/feature-interval26mm-recessed-banner-edge.jpg";
import recessedBanner from "../../images/feature-interval26mm-recessed-bannerThin.jpg";
import surfaceBanner from "../../images/feature-interval26mm-surface-banner.jpg";
import suspendedThumbnail from "../../images/feature-interval26mm-thumbnail-suspended.jpg";
import surfaceThumbnail from "../../images/feature-interval26mm-thumbnail-surface.jpg";
import recessedThumbnail from "../../images/feature-interval26mm-thumbnail-recessed.jpg";
import indexWBThumbnail from "../../../product_data/images/Index-IDX-C00-WB-Installed.jpg";
import indexWWThumbnail from "../../../product_data/images/Index-IDX-C00-WW-Installed.jpg";
import indexBBThumbnail from "../../../product_data/images/Index-IDX-C00-BB-Installed.jpg";
import index60RangeBanner from "../../images/feature-index60-ceiling.jpg";
import intervalThumbnailHero from "../../images/feature-interval26mm-thumbnail-hero.jpg";

export default function interval() {
  return (
    <>
      <Helmet>
        <title>Interval 26mm 48V Magnetic Track — Ratio Lighting</title>
        <meta property="og:title" content="Contact Us" key="ogtitle" />
        <meta
          property="og:image"
          content={`https://ratio.lighting${intervalThumbnailHero}`}
        />
        <meta
          property="og:title"
          content="Interval 26mm 48V Magnetic Track — Ratio Lighting"
          key="ogtitle"
        />
        <meta
          property="og:description"
          content="We took our full-featured Index 60mm Adjustable spots and put them on a magnetic track to offer uncompromising flexibility for any lighting scenario."
          key="ogdesc"
        />
        <meta
          name="description"
          content="We took our full-featured Index 60mm Adjustable spots and put them on a magnetic track to offer uncompromising flexibility for any lighting scenario."
        />
      </Helmet>

      <main id="main">
        <div className="grid-content-footer px-medium pt-small">
          <article className="grid-child-content pb-large pb-mm-xl">
            <div className="feature-page pt-xs">
              <div className="sticky feature-page-sticky mb-large">
                <div className="px-small pt-small pb-small grid-fixed-2">
                  <div className="column">
                    <h1>
                      <span className="fw-medium">Interval 26mm</span>
                      <br />
                      48V Magnetic Track
                    </h1>
                  </div>
                  <div className="column text-align-right">
                    <a
                      href="mailto:general@ratio.lighting?subject=Enquiry: Interval 26mm x Index 60mm Magnetic 48V Adjustable"
                      className="capsule-link button-capsule-link"
                    >
                      Enquire
                    </a>
                  </div>
                </div>
              </div>

              <div className="feature-page-banner pb-large">
                <img
                  src={recessedBanner}
                  alt="Interval 26mm Recessed with 2 Index 60mm Spots"
                />
              </div>

              <div className="px-small pt-large pb-large text-align-center">
                <h1 className="fs-xl fs-ml-xxxl">
                  Magnetic Fixation, Maximum Flexibility,
                  <br />
                  <span className="fw-medium">Astonishingly Diminutive.</span>
                </h1>
              </div>

              <div className="feature-page-content px-small pt-large pb-large fs-large fs-ml-xl">
                <p>
                  We took our full-featured Index 60mm Adjustable spots and put
                  them on a magnetic track to offer uncompromising flexibility
                  for any lighting scenario. Individual fixtures click into
                  place securely and safely without the need of any tools,
                  meaning they can be moved effortlessly, or swapped out for
                  another configuration to achieve a completely different
                  kind&nbsp; of&nbsp;illumination.
                </p>
                <p>
                  This level of immediate freedom is ideal for museum, gallery
                  and retail floors, but should be considered for any space
                  where end users would like to easily recalibrate lighting to
                  accommodate changes in layout or application. Specify Interval
                  alongside other fixtures in the Index family to achieve a
                  uniform aesthetic across the entire&nbsp;project.
                </p>
                <p>
                  Interval can be installed trimless in plasterboard to create
                  26mm wide channels, or surface mounted at a diminutive height
                  of 56mm. Suspension installation is also available, and in
                  this configuration spotlights can be supplemented with
                  indirect uplight on the spine providing
                  additional&nbsp;ambient&nbsp;illumination.
                </p>
              </div>

              <div className="feature-page-table py-large">
                <table className="simple-table">
                  <tr className="fw-medium">
                    <th colSpan="3">Interval 26mm</th>
                  </tr>
                  <tr className="fw-medium text-align-center">
                    <td>Recessed</td>
                    <td>Surface</td>
                    <td>Suspended</td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src={recessedThumbnail}
                        alt="Interval 26mm Recessed"
                      />
                    </td>
                    <td>
                      <img
                        src={surfaceThumbnail}
                        alt="Interval 26mm Surface Mounted"
                      />
                    </td>
                    <td>
                      <img
                        src={suspendedThumbnail}
                        alt="Interval 26mm Suspended"
                      />
                    </td>
                  </tr>
                  <tr className="text-align-center">
                    <td>Black</td>
                    <td>Black, White</td>
                    <td>Black, White</td>
                  </tr>
                  <tr className="text-align-center">
                    <td>26mm</td>
                    <td>26x54mm</td>
                    <td>26x54mm</td>
                  </tr>
                  <tr className="text-align-center">
                    <td>—</td>
                    <td>Cover & End Caps</td>
                    <td>Cover & End Caps</td>
                  </tr>
                  <tr className="text-align-center">
                    <td>—</td>
                    <td>—</td>
                    <td>Indirect Uplight via 24V LED Strip</td>
                  </tr>
                  <tr className="text-align-center">
                    <td colSpan="3">
                      L-Joint, T-Joint and X-Joint connection accesories.
                    </td>
                  </tr>
                  <tr className="text-align-center">
                    <td colSpan="3">
                      DALI Control. Casambi Bluetooth Gateway available.
                    </td>
                  </tr>
                  <tr className="text-align-center">
                    <td colSpan="3">
                      Interval can be supplied in lengths up to 3m. We recommend
                      ordering lengths of 2m and cutting on site to exact fit.
                    </td>
                  </tr>
                </table>
              </div>

              <div className="feature-page-banner py-large">
                <img
                  src={surfaceBanner}
                  alt="Interval 26mm Recessed with 2 Index 60mm Spots"
                />
              </div>

              {/* <div className="feature-page-table px-small py-large">
                <div className="grid-fixed-1 grid-fixed-mm-2">
                  <div className="column feature-table">Left</div>
                  <div className="column feature-table">Right</div>
                </div>
              </div> */}

              <div className="feature-page-table feature-page-table-narrow py-large">
                <table className="simple-table">
                  <tr className="fw-medium">
                    <th colSpan="3"> Index 60mm Magnetic 48V Adjustable</th>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src={indexWWThumbnail}
                        alt="Index 26mm Recessed WW"
                      />
                    </td>
                    <td>
                      <img
                        src={indexWBThumbnail}
                        alt="Index 26mm Recessed WB"
                      />
                    </td>
                    <td>
                      <img
                        src={indexBBThumbnail}
                        alt="Index 26mm Recessed BB"
                      />
                    </td>
                  </tr>
                  <tr className="text-align-center">
                    <td colSpan="3">2700K, 3000K, 4000K</td>
                  </tr>
                  <tr className="text-align-center">
                    <td colSpan="3">
                      894-1185lm
                      <br />
                      <span className="fs-tiny c-fade">
                        Based on configuration
                      </span>
                    </td>
                  </tr>
                  <tr className="text-align-center">
                    <td colSpan="3">
                      Oval 19x35°
                      <br />
                      Spot 22°
                      <br />
                      Medium 30°
                      <br />
                      Flood 40°
                      <br />
                      Wide Flood 46°
                      <br />
                      Wall Wash
                    </td>
                  </tr>
                  <tr className="text-align-center">
                    <td colSpan="3">
                      Tilt Angle 0-90°
                      <br />
                      360° Rotation
                    </td>
                  </tr>
                  <tr className="text-align-center">
                    <td colSpan="3">IP20</td>
                  </tr>
                  <tr className="text-align-center">
                    <td colSpan="3">Optional Honeycomb Louvre</td>
                  </tr>
                  <tr className="text-align-center">
                    <td colSpan="3">
                      Integrated DALI Control
                      <br />
                      Casambi Bluetooth ready
                      <br />
                      <span className="fs-tiny c-fade">Driver Included</span>
                    </td>
                  </tr>
                </table>
              </div>

              <div className="feature-page-cta px-small pt-large pb-small text-align-center">
                <h3 className="fs-large fs-ml-xl">
                  For configuration options, specification sheets and
                  Photometry:
                </h3>
              </div>

              <div className="feature-page-content px-small pb-large text-align-center">
                <ul className="button-capsule-link-row button-capsule-link-row-center">
                  <li>
                    <a
                      href="/range/index/index-60mm-magnetic-48v-adjustable/"
                      className="capsule-link button-capsule-link capsule-link-primary"
                    >
                      Index 60mm Magnetic 48V Adjustable
                    </a>
                  </li>
                  <li>
                    <a
                      href="/range/index/index-60mm-magnetic-48v-adjustable-wall-wash/"
                      className="capsule-link button-capsule-link capsule-link-primary"
                    >
                      Index 60mm Magnetic 48V Adjustable Wall Wash
                    </a>
                  </li>
                </ul>
              </div>

              <hr className="mt-large mb-large" />

              <div className="feature-page-banner-1000 py-large">
                <img
                  src={index60RangeBanner}
                  alt="Our Index 60mm Range of ceiling mounted fixtures"
                />
              </div>

              <div className="feature-page-content px-small pt-large fs-large fs-ml-xl">
                <p>
                  The Index family comprises recessed, adjustable and fixed
                  downlights to meet any illumination requirement. Specify
                  Interval alongside other fixtures in the Index family to
                  achieve a uniform aesthetic across the entire project.
                </p>

                <p>
                  Every project is different. We’re on deck to help ensure that
                  the solutions and configurations specified are exactly right
                  for your project. Drop us an email and we’ll get back to you
                  promptly.
                </p>
              </div>

              <div className="feature-page-content px-small pt-large pb-large text-align-center">
                <a
                  href="mailto:general@ratio.lighting?subject=Enquiry: Interval 26mm x Index 60mm Magnetic 48V Adjustable"
                  className="capsule-link button-capsule-link"
                >
                  Enquire
                </a>
              </div>
            </div>
          </article>
        </div>
      </main>
    </>
  );
}
